import React from 'react'
import { BsFacebook } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'
import { RiWhatsappFill } from 'react-icons/ri'
import { AiFillInstagram } from 'react-icons/ai'

function SocialMedia() {
  return (

    <div className='flex  TSosyalIcon hover:bg-yellow-200 hover:rounded-lg 2xl:gap-x-10 2xl:pr-10 gap-x-5 font-extrabold'>
      <a target="_blank" href="https://www.instagram.com/eksen_reklam/" rel='noreferrer' className='hover:bg-yellow-500'>{<AiFillInstagram size={32} />}</a>
      <a target="_blank" href="https://www.facebook.com/profile.php?id=100087185599393&mibextid=ZbWKwl" rel='noreferrer' className='hover:bg-yellow-500'>{<BsFacebook size={28} />}</a>
      <a target="_blank" href="https://www.tiktok.com/@eksen_reklam?_t=8WxronHiETq&_r=1" rel='noreferrer' className='hover:bg-yellow-500'>{<FaTiktok size={28} />}</a>
      <a target="_blank" href="https://wa.me/905307971187?
      text= Merhaba%20hizmetleriniz%20hakkında%20bilgi%20almak%20istiyorum." rel='noreferrer' className='hover:bg-yellow-500'>{<RiWhatsappFill size={30} />}</a>
    </div>
  )
}

export default SocialMedia