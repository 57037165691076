import React from 'react'
import { Link } from 'react-router-dom'
function Page404(props) {
  return (
    <div className=" h-screen  text-center 2xl:mx-80 text-4xl text-black">
        <div className="mt-40">
        <h2>SAYFA BULUNAMADI!</h2>
        <h2>Sayfa kaldırılmış veya değiştirilmiş olabilir.<br/>
Anasayfaya dönmek için lütfen aşağıdaki linke tıklayın. <div className="mt-10 text-yellow-600 italic"><Link to="/">Eksen Reklam</Link></div></h2>
        </div>
    </div>
  )
}

export default Page404