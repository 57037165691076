import React,{useEffect} from 'react'
import kutuHarf from '../img/on.jpeg'
import atasay from '../img/atasay.jpeg'
import cepheGiydirme from '../img/CepheGiydirme.jpg'
import folyo from '../img/1.jpg'
import endrek from '../img/3.jpg'
import alicor from '../img/4.jpeg'
import pleksi from '../img/5.jpeg'
import krom from '../img/7.jpeg'
import yanaklıPleksi from '../img/8.jpeg'
import KKromHarf from '../img/orsKuyumcu.jpeg'
import ASicker from '../img/otobus.jpeg'
import LogoTasarım from '../img/protez.jpeg'
import SahsiArac from '../img/sahsiArac.jpeg'
import TicariArac from '../img/ticariArac.jpeg'
import IKromHarf from '../img/yerdeki.jpeg'
import skargo from '../img/sKargo.jpeg'
import insaatE from '../img/insaatE.jpeg'
import { Helmet } from 'react-helmet'
import Aos from 'aos'
import 'aos/dist/aos.css'




function OurServices() {
  useEffect (() => {
    Aos.init({duration:2000})
  },[]) 
  return (
    <>

      <div className='flex Tservice   flex-wrap  mt-16 md:box-content md:max-xl:flex justify-center items-center 
    w-full 2xl:mx-32 max-w-full space-x-5 2xl:space-y-14 space-y-14'>
        <Helmet>
          <title>Hizmetlerimiz</title>
        </Helmet>
        <div data-aos='fade-up' className="Tserviceitem my-5 mt-20 border-2 mx-4   justify-center items-center h-[180px] w-[250px]">
          <div>
            <div className="h-[180px] w-[350px]   bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${insaatE})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Lamberi Kompozit <br/> Üzerine Kutu Harf</h3>
        </div>
        <div data-aos='fade-up' className="Tserviceitem my-5 border-2 mx-4   justify-center items-center h-[180px] w-[250px]">
          <div>
            <div className="h-[180px] w-[350px]   bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${skargo})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Araç Logo Uygulama</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${folyo})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Tabela Folyo Uygulama</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${endrek})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Arkadan Işıklı <br/>Endrek Krom Harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${alicor})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Işıksız Alicor <br/>Kabartma harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${pleksi})` }}></div>
          </div>
          <h3 className='flex justify-start'> Izgara Üzerine<br/> Kutu Harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${krom})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'> Işıklı Krom Harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${cepheGiydirme})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Cephe Giydirme</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${KKromHarf})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Kapalı Krom Harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${ASicker})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Araç Sticker</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${SahsiArac})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Kişisel Araç Kaplama</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px]">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${TicariArac})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Ticari Araç Kaplama</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${LogoTasarım})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-2 2xl:mb-2'>Firmaya Özel <br/>Logo Tasarımı</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${IKromHarf})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-2 2xl:mb-2'>Endrek Işıklı <br/>Krom Harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${atasay})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Pleksi Kaplama <br/>Işıklı Krom Harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-7 border-2 mx-4  justify-center items-center h-[180px] w-[250px] ">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] pb-4 hover:scale-125"
              style={{ backgroundImage: `url(${yanaklıPleksi})` }}></div>
          </div>
          <h3 className='flex justify-start  2xl:mt-3'>Alüminyum Yanaklı <br/>Pleksi Kutu Harf</h3>
        </div>
        <div data-aos='fade-up' className=" my-5 border-2 mx-4  justify-center items-center h-[180px] w-[250px]">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px] hover:scale-125"
              style={{ backgroundImage: `url(${kutuHarf})` }}></div>
          </div>
          <h3 className='flex justify-start 2xl:mt-3'>Oyma Kompozit Üzerine<br/> Pileksi Kutu Harf</h3>
        </div>
      </div>
    </>

  )
}

export default OurServices