import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import SocialMedia from './SocialMedia';


const Ul = styled.ul`{
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 20px 10px;
  }
  @media (min-width: 769px) {
    display:none;}
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;z-index:20;
    right: 0;
    height: 500px;
    width: 230px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open} className='text-xl'>
      <li>
      <NavLink className="hover:bg-purple-300
                    hover:rounded-md"  to="/">Anasayfa</NavLink>
      </li>
      <li><NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/about">Hakkımızda</NavLink></li>
      <li>
      <NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/ourServices">Hizmetlerimiz</NavLink>
      </li>
      <li>
      <NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/reference">Referanslarımız</NavLink>
      </li>
      <li>
      <NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/contact">İletişim</NavLink>
      </li>
      <div className='ml-4 mt-5 text-slate-100 '>
      <SocialMedia/>
      </div>
    </Ul>
  )
}

export default RightNav