import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes} from 'react-router-dom';
import About from './components/About'
import Home from './components/Home'
import Header from './components/Header'
import OurServices from './components/OurServices';
import References from './components/References';
import Contact from './components/Contact';
import SocialMedia from './components/SocialMedia';
import 'App.css'
import FadeLoader from 'react-spinners/FadeLoader'
import Page404 from 'components/Page404';

export default function App() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])
  return (


    <>
      <div className="container  justify-center items-center Telefon ">
        {
          loading ?
            <div className='Tloader mr-1 2xl:ml-80 flex items-center justify-center h-screen'>
              <FadeLoader
                size={80}
                color="yellow"
                loading={loading}
              />
              <h1 className='mt-24 -mx-32 font-bold'>Hayata Bir Reklam Arası</h1>
            </div>

            :
            <>
              <Header />
              <Routes>
                <Route path="/*" element={<Page404 />} />
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/ourServices" element={<OurServices />} />
                <Route path="/reference" element={<References />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/socialMedia" element={<SocialMedia />} />
                <Route path='*' element={<Navigate to="/"/>}/>
              </Routes>
            </>
        }
      </div>

    </>
  );
}
